import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, IconEdit, IconDelete } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../system/Translate';
import UserCan from '../../auth/UserCan';

export default function TemplateActions(props) {
    const { id, shopGroup } = props;
    const { translate } = useContext(TranslateContext);
    return (
        <>
            <Link to={`/edit/${id}/${shopGroup}`} className="cn-template-action-link">
                <Icon InlineIcon={IconEdit} />
                {translate('edit')}
            </Link>
            <UserCan
                perform="remove_template"
                yes={() => (
                    <Link to={`/delete/${id}`} className="cn-template-action-link">
                        <Icon InlineIcon={IconDelete} />
                        {translate('delete')}
                    </Link>
                )}
            />

        </>
    );
}

TemplateActions.propTypes = { id: PropTypes.string.isRequired, shopGroup: PropTypes.string.isRequired };
