import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Input,
    FormRow,
    Button,
    GridCol,
    Grid,
    Select,
    Textarea,
    SelectOption,
    Link,
    Infobox,
    ImageBox,
} from '@flixbus/honeycomb-react';
import {
    Icon,
    IconChatAlt,
    IconDelete,
    IconNewsletter,
    IconNewTab,
    IconNotification,
    IconPlus,
    IconWhatsapp,
} from '@flixbus/honeycomb-icons-react';
import PlaceholdersPopup from './PlaceholdersPopup';
import Editor from '../../Editor';
import { SMSCounter } from '../../../helpers/SMSCounter';
import PreviewPopup from './PreviewPopup';
import { TranslateContext } from '../../../system/Translate';
import useUserCan from '../../../auth/useUserCan';
import templateImage from '../../../assets/fb_template_name.png';
import { ConfigContext } from '../../../context';

const MAX_PLACEHOLDERS = {
    bodyPlaceholders: 20,
    headerPlaceholder: 1,
    buttonPlaceholder: 1,
};

const channelIcons = {
    'sms': IconChatAlt,
    'whatsapp': IconWhatsapp,
    'email': IconNewsletter,
    'push': IconNotification,
}

const getAllUniquePlaceholders = (cases) => {
    const placeholderSet = new Set();

    cases.forEach((caseItem) => {
        caseItem.placeholders.forEach((placeholder) => {
            placeholderSet.add(placeholder);
        });
    });

    return Array.from(placeholderSet);
}

const getPlaceholderOptions = (config, assignedCase) => {
    if (!config) {
        return [];
    }

    if (assignedCase) {
        const caseItem = config.automatedCases.find((item) => item.name === assignedCase);
        return caseItem ? caseItem.placeholders : [];
    }

    return getAllUniquePlaceholders(config.automatedCases);
}

// TODO: split functionality for channels between files
export default function TextEditor({
    onSave,
    onUpdate,
    onDelete,
    deletionPending,
    subject: initSubject,
    content: initContent,
    onPushTemplateUpdate,
    pending,
    activeLang,
    languages,
    setActiveLang,
    assignedCase,
    subjectError,
    contentError,
    channel,
    initialPlaceholders,
    templateId,
    androidTitle: initAndroidTitle,
    appleCustomUri: initAppleCustomUri,
    androidCustomUri: initAndroidCustomUri,
}) {
    const location = useLocation();
    const isNewTemplate = location?.state?.isNewTemplate;
    const { translate } = useContext(TranslateContext);
    const [subject, setSubject] = useState(initSubject);
    const [content, setContent] = useState(initContent);
    const [androidTitle, setAndroidTitle] = useState(initAndroidTitle);
    const [appleCustomUri, setAppleCustomUri] = useState(initAppleCustomUri);
    const [androidCustomUri, setAndroidCustomUri] = useState(initAndroidCustomUri);
    const [smsCount, setSmsCount] = useState();
    const config = useContext(ConfigContext);

    const [placeholders, setPlaceholders] = useState({});

    useEffect(() => {
        const mapPlaceholders = (placeholdersList) =>
            placeholdersList.map((placeholder, index) => ({ id: index, value: placeholder }));

        setPlaceholders({
            bodyPlaceholders: mapPlaceholders(initialPlaceholders.bodyPlaceholders),
            headerPlaceholder: mapPlaceholders(initialPlaceholders.headerPlaceholder),
            buttonPlaceholder: mapPlaceholders(initialPlaceholders.buttonPlaceholder),
        });
    }, [initialPlaceholders]);

    const isUserCanEditTemplate = useUserCan('edit_template');

    const placeholderOptions = useMemo(() => getPlaceholderOptions(config, assignedCase), [config, assignedCase]);

    useEffect(() => {
        const processPlaceholderArray = (placeholderArray) => {
            return placeholderArray?.map((pl) => {
                if (!placeholderOptions.includes(pl.value)) {
                    return { ...pl, value: placeholderOptions[0] };
                }
                return pl;
            });
        };

        const processedBodyPlaceholders = processPlaceholderArray(placeholders.bodyPlaceholders);
        const processedHeaderPlaceholder = processPlaceholderArray(placeholders.headerPlaceholder);
        const processedButtonPlaceholder = processPlaceholderArray(placeholders.buttonPlaceholder);

        if (JSON.stringify(processedBodyPlaceholders) !== JSON.stringify(placeholders.bodyPlaceholders) ||
            JSON.stringify(processedHeaderPlaceholder) !== JSON.stringify(placeholders.headerPlaceholder) ||
            JSON.stringify(processedButtonPlaceholder) !== JSON.stringify(placeholders.buttonPlaceholder)) {

            setPlaceholders({
                bodyPlaceholders: processedBodyPlaceholders,
                headerPlaceholder: processedHeaderPlaceholder,
                buttonPlaceholder: processedButtonPlaceholder,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeholderOptions]);


    const addPlaceholder = (type) => {
        const newPlaceholder = {
            id: placeholders[type].length > 0 ? placeholders[type][placeholders[type].length - 1].id + 1 : 0,
            value: placeholderOptions[0],
        };

        setPlaceholders((prev) => ({
            ...prev,
            [type]: [...prev[type], newPlaceholder],
        }));
    };

    const removePlaceholder = (type, id) => {
        setPlaceholders((prev) => ({
            ...prev,
            [type]: prev[type].filter((placeholder) => placeholder.id !== id),
        }));
    };

    const handlePlaceholderChange = (type, id, newValue) => {
        setPlaceholders((prev) => ({
            ...prev,
            [type]: prev[type].map((placeholder) => {
                if (placeholder.id === id) {
                    return { ...placeholder, value: newValue };
                }
                return placeholder;
            }),
        }));
    };

    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const langOptions = [...languages].sort(compare).map((lang) => {
        return { displayValue: lang.name, value: lang.code };
    });

    useEffect(() => {
        const processPlaceholders = (placeholderArray) => {
            return placeholderArray?.reduce((acc, curr) => {
                return `${acc}${curr.value},`;
            }, '').slice(0, -1);
        };

        const bodyPlaceholders = processPlaceholders(placeholders?.bodyPlaceholders);
        const headerPlaceholder = processPlaceholders(placeholders?.headerPlaceholder);
        const buttonPlaceholder = processPlaceholders(placeholders?.buttonPlaceholder);

        const placeholdersObject = {
            payloadConfig: bodyPlaceholders,
            headerPlaceholder,
            buttonPlaceholder,
        };

        onUpdate(subject, content, channel !== 'whatsapp' ? null : placeholdersObject);
    }, [subject, content, placeholders, onUpdate, channel]);


    useEffect(() => {
        if(channel === 'push') {
            onPushTemplateUpdate(content, androidTitle, androidCustomUri, appleCustomUri)
        }
    }, [content, appleCustomUri, androidCustomUri, androidTitle, channel, onPushTemplateUpdate])


    // put `pending` as a dependency to force update state after template was saved
    // initSubject and initContent might be the same for different translations
    // and state wont be updated
    useEffect(() => {
        // update sate from props in case pending was changed to the false
        // after update is finished
        if (pending === false) {
            setSubject(initSubject);
            setContent(initContent);
            setAndroidTitle(initAndroidTitle)
            setAndroidCustomUri(initAndroidCustomUri)
            setAppleCustomUri(initAppleCustomUri)
        }
    }, [initSubject, initContent, pending, initAndroidTitle, initAndroidCustomUri, initAppleCustomUri]);

    // update content field length counter
    useEffect(() => {
        if (channel !== 'sms') return;

        const count = SMSCounter.getCount(content);

        setSmsCount(count);
    }, [channel, content]);

    function setChanges() {
        onSave();
    }

    const processValue = (value) => {
        return value.replace(/\[\[([^[\]]+)\]\]/g, (match, p1) => `[[${p1.replace(/\s+/g, '')}]]`);
    }

    const handleSubjectChange = (value) => {
        const processedValue = processValue(value);
        setSubject(processedValue);
    }

    const handleContentChange = (value) => {
        const processedValue = processValue(value);
        setContent(processedValue);
    }

    return (
        <div className="nc-template-form">
            <Grid align="top">
                {channel === 'whatsapp' && (
                    <GridCol size={12}>
                        <Infobox small style={{ marginBottom: '40px' }}>
                            <p>
                                {translate('ensure_the_template_name')}
                                {' '}
                                <strong>{translate('matches_exactly')}</strong>
                                {' '}
                                {translate('with_facebook_website')}
                                {' '}
                                <strong>{translate('remains_consistent')}</strong>
                                {' '}
                                {translate('copy_the_url')}
                            </p>
                            <ImageBox
                                text={translate('template_name_example')}
                                captioned
                                img={{
                                    src: templateImage,
                                    alt: 'template_name',
                                }}
                            />
                        </Infobox>
                    </GridCol>
                )}
                {channel === 'push' && (
                <GridCol size={6}>
                    <FormRow>
                        <Input
                            id="push-android-title"
                            label={translate('android_title')}
                            disabled={!isUserCanEditTemplate}
                            value={androidTitle}
                            onChange={({ target }) => setAndroidTitle(target.value)}
                            valid={!!androidTitle.length}
                            infoError={translate('android_title_required')}
                        />
                    </FormRow>
                </GridCol>
                )}
                {(channel === 'email' || channel === 'whatsapp') && (
                    <GridCol size={6}>
                        <FormRow>
                            <Input
                                disabled={!isUserCanEditTemplate}
                                id="subject"
                                label={channel !== 'whatsapp' ? translate('subject') : translate('template_name')}
                                value={subject}
                                onChange={({ target }) => {
                                    handleSubjectChange(target.value);
                                }}
                                valid={subjectError ? false : null}
                                infoError={subjectError}
                            />
                        </FormRow>
                    </GridCol>
                )}
                <GridCol size={6}>
                    <FormRow>
                        <Select
                            id="language-list"
                            extraClasses="cn-template__language-select"
                            label={translate('translation-language')}
                            options={langOptions}
                            onChange={({ target }) => setActiveLang(target.value)}
                            value={activeLang}
                        >
                            {langOptions.map((lang) => {
                                return (
                                    <SelectOption key={lang.value} value={lang.value}>
                                        {lang.displayValue}
                                    </SelectOption>
                                );
                            })}
                        </Select>
                    </FormRow>
                </GridCol>
            </Grid>
            <Grid align="center">
                <GridCol size={12}>
                    <FormRow>
                        {channel === 'whatsapp' && (
                            <>
                                <Input
                                    extraClasses="cn-template__whatsapp-id"
                                    label={translate('template_url')}
                                    id="whatsapp-id"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                                <Link target='_blank' href={content}>
                                    {translate('open_template')}
                                    {' '}
                                    <Icon
                                        style={{fill: 'currentColor'}}
                                        InlineIcon={IconNewTab}
                                        aria-label="Opens in a New Tab"
                                    />
                                </Link>
                            </>
                        )}
                        {channel === 'email' && (
                            <Editor
                                label={translate('template-content')}
                                content={content}
                                onChange={(e) => {
                                    handleContentChange(e.data);
                                }}
                                activeLang={activeLang}
                                error={contentError}
                            />
                        )}
                        {(channel === 'sms' || channel === 'push') && (
                            <Textarea
                                disabled={!isUserCanEditTemplate}
                                id="template-content"
                                label={translate('template-content')}
                                value={content}
                                onChange={({ target }) => {
                                    handleContentChange(target.value);
                                }}
                                valid={contentError ? false : null}
                                infoError={contentError}
                            />
                        )}
                        {channel === 'push' && (
                            <Grid>
                                <GridCol size={10}>
                                    <FormRow style={{ marginTop: '20px' }} spacing="4">
                                        <Input
                                            id="push-ios-deeplink"
                                            label={translate('ios_deeplink')}
                                            value={appleCustomUri}
                                            onChange={({ target }) => {
                                                setAppleCustomUri(target.value);
                                            }}
                                        />
                                    </FormRow>
                                </GridCol>

                                <GridCol size={10}>
                                    <FormRow spacing="1">
                                        <Input
                                            id="push-android-deeplink"
                                            label={translate('android_deeplink')}
                                            value={androidCustomUri}
                                            onChange={({ target }) => {
                                                setAndroidCustomUri(target.value);
                                            }}
                                        />
                                    </FormRow>
                                </GridCol>

                            </Grid>
                        )}
                        <div className="content-wrapper">
                            {channel !== 'whatsapp' && <PlaceholdersPopup automatedCase={assignedCase} />}

                            {smsCount && (
                                <div className="content-wrapper__content-count">
                                    {translate('characters-remaining')}
                                    :
                                    {smsCount.remaining} 
                                    (
                                    {smsCount.numberOfSMS} 
                                    sms)
                                </div>
                            )}
                            {channel === 'email' && <PreviewPopup content={content} />}
                        </div>
                    </FormRow>
                </GridCol>

                <GridCol size={8}>
                    {(channel === 'whatsapp') && (
                        <FormRow>
                            <div className="cn-template__placeholders">
                                {Object.entries(placeholders).map(([type, placeholderArray]) => (
                                    <div key={type} className="cn-template__placeholder-section">
                                        <h3>{translate(`${type}`)}</h3>
                                        {placeholderArray.length > 0 && placeholderArray.map((placeholder, index) => (
                                            <div key={placeholder.id} className="cn-template__placeholder-wrapper">
                                                <Select
                                                    style={{ minWidth: '300px' }}
                                                    id={`select_placeholder_${type}_${index}`}
                                                    onChange={(e) =>
                                                        handlePlaceholderChange(type, placeholder.id, e.target.value)}
                                                    label={placeholderArray.length > 1 &&
                                                        `${translate('placeholder')} #${index + 1}`}
                                                    value={placeholder.value}
                                                >
                                                    {placeholderOptions.map((option) => (
                                                        <SelectOption key={option} value={option}>
                                                            {option}
                                                        </SelectOption>
                                                    ))}
                                                </Select>
                                                <Button
                                                    extraClasses="cn-template__remove-placeholder"
                                                    display="square"
                                                    onClick={() => removePlaceholder(type, placeholder.id)}
                                                    appearance="tertiary"
                                                >
                                                    <Icon InlineIcon={IconDelete} />
                                                </Button>
                                            </div>
                                        ))}
                                        {placeholderArray.length < MAX_PLACEHOLDERS[type] && (
                                            <Button
                                                display="square"
                                                appearance="secondary"
                                                onClick={() => addPlaceholder(type)}
                                            >
                                                <Icon InlineIcon={IconPlus} />
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </FormRow>
                    )}


                    <FormRow>
                        <div className="cn-template__channel-icon">
                            <Icon InlineIcon={channelIcons[channel]} size={8} />
                        </div>
                    </FormRow>
                </GridCol>
            </Grid>

            <FormRow>
                <div className="cn-template__controls">
                    <Button
                        disabled={!isUserCanEditTemplate || !androidTitle.length}
                        type="button"
                        onClick={setChanges}
                        loading={pending}
                        appearance="primary"
                    >
                        {translate('save')}
                    </Button>
                    {isNewTemplate ? (
                        <Button loading={deletionPending} onClick={onDelete}>{translate('cancel')}</Button>
                    ) : (
                        <Button to="/" RouterLink={RouterLink}>
                            {translate('cancel')}
                        </Button>
                    )}
                    <Button
                        appearance="danger"
                        loading={deletionPending}
                        to={`/delete/${templateId}`}
                        RouterLink={RouterLink}
                    >
                        {translate('delete')}
                    </Button>
                </div>
            </FormRow>
        </div>
    );
}
TextEditor.propTypes = {
    onSave: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    subject: PropTypes.string,
    content: PropTypes.string,
    pending: PropTypes.bool,
    languages: PropTypes.array,
    activeLang: PropTypes.string,
    setActiveLang: PropTypes.func,
    assignedCase: PropTypes.string,
    subjectError: PropTypes.string,
    contentError: PropTypes.string,
    channel: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    deletionPending: PropTypes.bool.isRequired,
    initialPlaceholders: PropTypes.object,
    templateId: PropTypes.string,
    onPushTemplateUpdate: PropTypes.func,
    androidTitle: PropTypes.string,
    appleCustomUri: PropTypes.string,
    androidCustomUri: PropTypes.string,
};
TextEditor.defaultProps = {
    subject: '',
    content: '',
    pending: false,
    languages: [],
    activeLang: '',
    setActiveLang: () => {},
    assignedCase: '',
    subjectError: null,
    contentError: null,
    initialPlaceholders: {},
    templateId: '',
    onPushTemplateUpdate: () => {},
    androidTitle: '',
    appleCustomUri: '',
    androidCustomUri: '',
};
