import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Grid, GridCol, FormRow, Button, Skeleton, Popup, PopupSection } from '@flixbus/honeycomb-react';
import { Icon, IconCrossed } from '@flixbus/honeycomb-icons-react';
import { useApi } from '../../hooks';
import { TranslateContext } from '../../system/Translate';

const DEFAULT_SUBJECT = 'Untitled';
const DEFAULT_CONTENT = 'Change text here';
const DEFAULT_CHANNEL = 'email';
const DEFAULT_SHOP_GROUP = 'FLIX';

export default function CreateForm() {
    const [response, request] = useApi('post', '/template');
    const { channel, shopGroup } = useParams();
    const history = useHistory();
    const { translate } = useContext(TranslateContext);

    useEffect(() => {
        if(channel === 'push') {
            request({
                content: DEFAULT_CONTENT,
                channel: channel || DEFAULT_CHANNEL,
                shopGroup: shopGroup || DEFAULT_SHOP_GROUP,
                androidButtons: [],
            });
        } else {
            request({
                subject: DEFAULT_SUBJECT,
                content: DEFAULT_CONTENT,
                channel: channel || DEFAULT_CHANNEL,
                shopGroup: shopGroup || DEFAULT_SHOP_GROUP,
            });
        }


    }, [request, channel, shopGroup]);

    return (
        <>
            {response.pending && (
                <div>
                    <Grid>
                        <GridCol size={12} xl={3}>
                            <Grid>
                                <GridCol size={12} xl={9} pushXl={3} lg={12}>
                                    <Skeleton width="sm" height="md" />
                                </GridCol>
                            </Grid>
                        </GridCol>
                        <GridCol size={12} xl={7}>
                            <Skeleton width="sm" height="md" />
                            <FormRow>
                                <Grid>
                                    <GridCol>
                                        <Skeleton height="lg" />
                                    </GridCol>
                                    <GridCol>
                                        <Skeleton height="lg" />
                                    </GridCol>
                                </Grid>
                            </FormRow>
                            <FormRow>
                                <Grid>
                                    <GridCol size={6}>
                                        <Skeleton height="lg" />
                                    </GridCol>
                                </Grid>
                            </FormRow>
                            <Grid>
                                <GridCol size={6}>
                                    <Skeleton height="lg" />
                                </GridCol>
                            </Grid>
                        </GridCol>
                    </Grid>
                </div>
            )}
            {response.data ? (
                <Redirect
                    to={{
                        pathname: `/edit/${response.data.id}/${shopGroup}`,
                        state: { isNewTemplate: true },
                    }}
                />
            ) : null}
            {response.error ? (
                <Popup active>
                    <PopupSection type="icon">
                        <Icon InlineIcon={IconCrossed} style={{ fill: 'var(--hcr-danger-color)' }} size="8" />
                    </PopupSection>
                    <PopupSection type="title">{translate('error-happened')}</PopupSection>
                    <PopupSection type="content">{translate('template-was-not-created')}</PopupSection>
                    <PopupSection>
                        <Button appearance="link" onClick={() => history.goBack()}>
                            {translate('back-to-list')}
                        </Button>
                    </PopupSection>
                </Popup>
            ) : null}
        </>
    );
}
