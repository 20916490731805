import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Button,
    Calendar,
    Grid,
    GridCol,
    Input,
    Select,
    SelectOption,
} from '@flixbus/honeycomb-react';
import { Icon, IconCalendar, IconReset } from '@flixbus/honeycomb-icons-react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../system/Translate';
import * as FILTERS from './FiltersList';
import ListPicker from '../FormElements/ListPicker';
import { useCloudFilters } from '../App/context/CloudFiltersContext';
import debounce from '../../helpers/debounce';
import './Filters.scss';

const channelOptions = [
    { id: 'email', value: 'email', label: 'E-mail'},
    { id: 'sms', value: 'sms', label: 'SMS'},
    { id: 'whatsapp', value: 'whatsapp', label: 'WhatsApp'},
    { id: 'push', value: 'push', label: 'Push'},
    { id: 'both', value: '', label: 'All' },
];

const initState = {
    [FILTERS.UPDATED_AT]: '',
    [FILTERS.SHOP_GROUP]: '',
    [FILTERS.CHANNEL]: '',
    [FILTERS.SUBJECT]: '',
    [FILTERS.CONTENT]: '',
    [FILTERS.UPDATED_BY]: '',
    [FILTERS.AUTOMATED_CASE]: '',
}

const Filters = ({ groupedData }) => {
    const { users, automatedCases: autoCases } = groupedData;
    const { translate } = useContext(TranslateContext);
    const [showCalendar, setShowCalendar] = useState(false);

    const [startDate, setStartDate] = useState(new Date(2019, 8, 1));
    const [startDateSelected, setStartDateSelected] = useState(null);
    const [endDateSelected, setEndDateSelected] = useState(null);

    const [filters, setFilters] = useState(initState);

    const containerRef = useRef(null);

    const { addFilter, resetFilters, removeFilterCategory, filters: cloudFilters } = useCloudFilters();

    const handleResetFilters = () => {
        setFilters(initState);
        setStartDateSelected(null);
        setEndDateSelected(null);
        resetFilters();
    }

    const handleInputChange = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));

        debouncedAddFilter(filterName, value);
    };

    const debouncedAddFilter = useCallback(
        debounce((filterName, value) => addFilter(filterName, value, true), 400),
        []
    );

    const handleCalendarSelect = (date) => {
        if (startDateSelected === null) {
            setStartDateSelected(date);
            setStartDate(date);
            return;
        }
        if (endDateSelected === null) {
            setEndDateSelected(date);
            setStartDate(new Date(2019, 8, 1));

            addFilter(
                FILTERS.UPDATED_AT,
                `${startDateSelected.toLocaleDateString()} - ${date.toLocaleDateString()}`,
                true
            )

            return;
        }
        if (endDateSelected && startDateSelected) {
            setEndDateSelected(null);
            setStartDateSelected(date);
            setStartDate(date);
        }

    }

    const clickOutside = useCallback(
        (e) => {
            if (containerRef.current.contains(e.target) === false) {
                setShowCalendar(false);
            }
        },
        [setShowCalendar]
    );

    // to hide calendar with outside clicks
    useEffect(() => {
        if (showCalendar === true) {
            document.addEventListener('click', clickOutside, false);
        } else {
            document.removeEventListener('click', clickOutside, false);
        }
        return () => {
            document.removeEventListener('click', clickOutside, false);
        };
    }, [showCalendar, clickOutside]);

    const handleAutocompleteSelection = (value, filterName) => {
        addFilter(filterName, value)
    }

    return (
        <Grid extraClasses="cn--filters">
            <GridCol size={4}>
                <Input
                    extraClasses="cn--filters__filter"
                    label={translate('subject')}
                    id={FILTERS.SUBJECT}
                    value={filters[FILTERS.SUBJECT]}
                    onChange={(e) => handleInputChange(FILTERS.SUBJECT, e.target.value)}
                />
            </GridCol>
            <GridCol size={4}>
                <Input
                    extraClasses="cn--filters__filter"
                    label={translate('content')}
                    id={FILTERS.CONTENT}
                    value={filters[FILTERS.CONTENT]}
                    onChange={(e) => handleInputChange(FILTERS.CONTENT, e.target.value)}
                />
            </GridCol>
            <GridCol size={3}>
                <div
                    ref={containerRef}
                    className="cn--filters__calendar-container"
                >
                    <Input
                        label={translate('updated')}
                        id={FILTERS.UPDATED_AT}
                        onFocus={() => { setShowCalendar(true)}}
                        readOnly
                        value={
                            (startDateSelected && endDateSelected) ?
                                `${startDateSelected.toLocaleDateString()} - ${endDateSelected.toLocaleDateString()}`
                                : ''
                        }
                        iconLeft={
                            <Icon appearance="primary" InlineIcon={IconCalendar} />
                        }
                    />
                    <Calendar
                        extraClasses='cn--filters__calendar'
                        id="date-filter"
                        hidden={!showCalendar}
                        startDate={startDate}
                        endDate={new Date()}
                        defaultMonth={new Date()}
                        startSelected={startDateSelected}
                        endSelected={endDateSelected}
                        appearance="compact"
                        handleSelect={handleCalendarSelect}
                    />
                </div>
            </GridCol>

            <GridCol size={4}>
                <ListPicker
                    label={translate('automated-case')}
                    id={FILTERS.AUTOMATED_CASE}
                    options={autoCases}
                    shouldResetValue
                    onSelect={(value) => handleAutocompleteSelection(value, FILTERS.AUTOMATED_CASE)}
                />
            </GridCol>
            <GridCol size={4}>
                <ListPicker
                    label={translate('user')}
                    id={FILTERS.UPDATED_BY}
                    options={users}
                    shouldResetValue
                    onSelect={(value) => handleAutocompleteSelection(value, FILTERS.UPDATED_BY)}
                />
            </GridCol>
            <GridCol extraClasses="cn--filters__select-group" size={4}>
                <Select
                    style={{ minWidth: '251px' }}
                    id={FILTERS.CHANNEL}
                    label={translate('channel')}
                    value={
                        (cloudFilters[FILTERS.CHANNEL] && cloudFilters[FILTERS.CHANNEL].size) ?
                            Array.from(cloudFilters[FILTERS.CHANNEL])[0]
                            : ''
                    }
                    onChange={({ target }) => {
                        if(!target.value) {
                            removeFilterCategory(FILTERS.CHANNEL);
                            return;
                        }
                        addFilter(FILTERS.CHANNEL, target.value, true);
                    }}
                >
                    {channelOptions.map(({ id, value, label } ) => {
                        return (
                            <SelectOption id={id} key={value} value={value}>{label}</SelectOption>
                        )
                    })}
                </Select>
                <Button
                    extraClasses="cn--filters__reset"
                    appearance='secondary'
                    display="square"
                    onClick={handleResetFilters}
                >
                    <Icon InlineIcon={IconReset} />
                </Button>
            </GridCol>
        </Grid>
    )
}

Filters.propTypes = {
    groupedData: PropTypes.object,
};
Filters.defaultProps = {
    groupedData: {},
};

export default Filters;